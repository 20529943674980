'Use strict';
import React, { useEffect, useState } from 'react';
import ViewStyle from '../ViewStyle';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FilterReporting from './Filter';
import moment from 'moment';
import PDFViewer from './PdfViewer';
import LoadingDialog from '../../utils/LoadingDialog/LoadingDialog';
import { toast } from 'react-toastify';

toast.configure({
  autoClose: 8000,
  draggable: true,
  position: 'bottom-center',
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'light',
});

function Reporting() {
  console.log('Reporting');

  // Calculer les dates par défaut pour le mois précédent
  const currentDate = moment();
  const defaultStartDate = currentDate
    .clone()
    .subtract(1, 'month')
    .startOf('month'); // 1er jour du mois dernier
  const defaultEndDate = currentDate
    .clone()
    .subtract(1, 'month')
    .endOf('month'); // Dernier jour du mois dernier

  const [displayEvent, setDisplayEvent] = useState(false);
  const [selectedResponsables, setSelectedResponsables] = useState([]);
  const [selectedContrats, setSelectedContrats] = useState([]);
  const [dates, setDates] = useState({
    not_date_debut: defaultStartDate,
    not_date_fin: defaultEndDate,
  });
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(undefined);

  const accessToken = useSelector((state) => state.accessToken);
  const history = useHistory();
  const breadLink = {
    view: 'Reporting',
    bread: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Reporting',
        href: '/reporting',
      },
    ],
  };

  const downloadExcel = () => {
    if (
      selectedResponsables.length > 0 &&
      selectedContrats.length > 0 &&
      moment(dates.not_date_debut).isValid() &&
      moment(dates.not_date_fin).isValid()
    ) {
      try {
        axios
          .put(
            process.env.REACT_APP_BACK_API_URL + '/client/get-excel',
            {
              displayEvent,
              selectedResponsables,
              selectedContrats,
              not_date_debut: dates.not_date_debut,
              not_date_fin: dates.not_date_fin,
            },
            {
              headers: { token: accessToken },
              responseType: 'blob',
            }
          )
          .then((response) => {
            if (response.data) {
              // download excel
              const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'reporting.xlsx'; // Set the filename for download
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url); // Clean up the Object URL
            } else {
              console.log('❌ Excel');
              toast.error('Erreur lors de la génération du fichier Excel');
              history.push('/');
            }
          });
      } catch (error) {
        throw error;
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function role() {
        try {
          axios
            .get(
              process.env.REACT_APP_BACK_API_URL + '/client/is-notifications',
              {
                headers: { token: accessToken },
              }
            )
            .then((response) => {
              if (response.data && mounted) {
                if (!isNaN(response.data.count) && response.data.count === 0) {
                  console.log('❌ Notifications');
                  history.push('/');
                }
                console.log('✅ Notifications');
              } else {
                console.log('❌ Notifications');
                history.push('/');
              }
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    if (
      mounted &&
      selectedResponsables.length > 0 &&
      selectedContrats.length > 0 &&
      moment(dates.not_date_debut).isValid() &&
      moment(dates.not_date_fin).isValid()
    ) {
      async function updatePDF() {
        try {
          setLoading(true);

          const response = await axios.put(
            process.env.REACT_APP_BACK_API_URL + '/client/get-pdf',
            {
              displayEvent,
              selectedResponsables,
              selectedContrats,
              not_date_debut: dates.not_date_debut,
              not_date_fin: dates.not_date_fin,
            },
            {
              headers: { token: accessToken },
              responseType: 'arraybuffer',
              signal: abortController.signal, // Ajout du signal d'annulation
            }
          );

          if (response.data && mounted) {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            setPdf(blob);
          } else {
            console.log('❌ PDF', response);
            toast.error('Erreur lors de la génération du PDF');
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Requête annulée', error.message);
          } else {
            console.log('❌ PDF CATCH', error);
            toast.error('Erreur lors de la génération du PDF');
          }
        } finally {
          if (mounted) setLoading(false);
        }
      }

      updatePDF();
    } else {
      setPdf(undefined);
    }

    return () => {
      mounted = false;
      abortController.abort(); // Annule la requête si le composant est démonté ou si une nouvelle requête est faite
    };
  }, [
    accessToken,
    history,
    selectedResponsables,
    selectedContrats,
    dates,
    displayEvent,
  ]);

  return (
    <ViewStyle
      noPadding
      breadLink={breadLink}
      width="xl"
      preChildren={
        <FilterReporting
          selectedResponsables={selectedResponsables}
          setSelectedResponsables={setSelectedResponsables}
          selectedContrats={selectedContrats}
          setSelectedContrats={setSelectedContrats}
          dates={dates}
          setDates={setDates}
          displayEvent={displayEvent}
          setDisplayEvent={setDisplayEvent}
        />
      }
    >
      <LoadingDialog openDialog={loading} />
      {pdf && !loading ? (
        <PDFViewer file={pdf} downloadExcel={downloadExcel} />
      ) : null}
    </ViewStyle>
  );
}

export default Reporting;
